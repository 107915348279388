import React from "react";
import { Link, withPrefix } from "gatsby";

import Layout from "../../../components/layout";
import SmallBanner from "../../../components/smallBanner";

const SingleOffer = () => {
	const lang = "de";

	return (
		<Layout
			lang={lang}
			seoTitle="Palette 4.0"
			translationPL="/oferta/paleta-4-0/"
			translationEN="/en/our-services/pallet-4-0/"
			isPallet4
		>
			<SmallBanner
				title="Palette 4.0"
				aditionalBreadcrump="Our services"
				aditionalBreadcrumpLink="/de/angebot/"
			/>

			<section className="pallet4-breaking">
				<div className="container">
					<h2 className="color--main">
						Ein bahnbrechendes Projekt auf dem Logistikmarkt
					</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								<strong>
									Die Palette 4.0 oder Intelligente Palette
									ist ein neues Produkt
								</strong>
								, das neben seiner Grundfunktionalität für den
								Transport und die Lagerung von Waren auch ein
								Informationsträger, ein sensorisches System und
								eine Drehscheibe für den Austausch logistischer
								Daten ist. Die Palette 4.0 ist ein hoch
								skalierbares Produkt mit vielen möglichen
								Konfigurationsoptionen.
							</p>
							<img
								className="pallet4-breaking__img--1"
								src={require("../../../assets/img/pallet4-content-image-1.png")}
								alt=""
							/>
							<p>
								<strong>
									Die Palette 4.0 gliedert sich in die neuen
									Ideen von Industrie 4.0 (Industry 4.0), dem
									Internet der Dinge (IoT) ein
								</strong>
								, setzt neue Instrumente zur Verbesserung der
								Sicherheit, der Kontrolle über den logistischen
								Prozess ein. Sie ist eine Lösung, die von den
								modernen Plattformen des Logistikmanagements
								unterstützt wird.
							</p>
						</div>
						<div className="col-lg-4">
							<img
								className="pallet4-breaking__img--2"
								src={require("../../../assets/img/pallet4-content-image-2.png")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-what">
				<div className="container">
					<h2 className="color--main">Was ist die Palette 4.0?</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								<strong>
									Die aus Holz gefertigte Palette 4.0 wird in
									einer modernen, vollständig kontrollierten
									Prozesslinie zusammengenagelt
								</strong>
								. Diese Technologie sorgt für eine höhere
								Zuverlässigkeit und mechanische Beständigkeit
								der Paletten.{" "}
								<strong>
									In der Palette sind elektronische
									Schaltkreise eingebaut
								</strong>
								, die u. a. folgende Funktionen erfüllen:
							</p>

							<div className="row">
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>sensorische Systeme</h4>
										<p>
											zur Erfassung der Umweltbedingungen
										</p>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>Produktidentifika tionssysteme</h4>
										<p>
											mit einem patentgeschützten System
											zur Verschlüsselung der Seriennummer
										</p>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>Funk - Kommunikations systeme</h4>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>Stromversorgungs systeme</h4>
										<p>
											unter Einsatz von der Energy
											Harvesting-Technologie
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-specs">
				<div className="container">
					<h2 className="color--main">Eigenschaften</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								Durch den Einsatz innovativer elektronischer
								Geräte{" "}
								<strong>
									kommuniziert die Palette 4.0 mit externen
									Geräten wie z.B. Telefonen
								</strong>{" "}
								und so kann sie unter anderem:
							</p>
							<ul>
								<li>
									die Seriennummer der Palette, die
									Bezugsnummer des Frachtbriefs, die
									Betriebsgeschichte, die Eigentumsform und
									-zugehörigkeit identifizieren. In Verbindung
									mit Logistikmanagementsystemen werden Daten
									zum logistischen Prozess ausgetauscht,
								</li>
								<li>
									Umweltrandbedingungen verfolgen wie z. B.:
								</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../../assets/img/pallet4-content-image-3.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<div className="row">
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-1.svg")}
												alt=""
											/>
										</div>
										<p>Temperatur</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-2.svg")}
												alt=""
											/>
										</div>
										<p>Feuchtigkeit</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-3.svg")}
												alt=""
											/>
										</div>
										<p>Gewicht der Ware</p>
									</div>
								</div>
								<div className="col-xl-3"></div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-4.svg")}
												alt=""
											/>
										</div>
										<p>mechanische Beschädigungen, Stöße</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-5.svg")}
												alt=""
											/>
										</div>
										<p>Neigung der Palette</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />
					<br />

					<div className="col-lg-8 offset-lg-4">
						<p>
							Das funkgesteuerte Identifikationssystem ist für
							autonome Hochregallager unerlässlich. Das schnelle
							Auslesen von Daten durch Transporteinrichtungen, die
							Widerstandsfähigkeit der Tags gegen mechanische
							Beschädigungen und die hohe Informationskapazität
							ermöglichen die Durchführung zuverlässiger
							Lagerprozesse in robotergesteuerten Lagern.
						</p>
						<p>
							<strong>
								Die Palette 4.0 zeichnet sich auch durch eine
								erhöhte Robustheit aus
							</strong>
							, durch die die Anzahl der Ladezyklen deutlich
							erhöht und mögliche Verluste aufgrund der
							Ausfallrate herkömmlicher Paletten reduziert werden
							können.
						</p>
					</div>
				</div>
			</section>

			<section className="pallet4-construction">
				<div className="container">
					<h2 className="color--main">Konstruktion</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<h4>
								Die Palette 4.0 kann aus mehreren Elementen
								gefertigt werden:
							</h4>
							<ul>
								<li>Holzpalette,</li>
								<li>
									hochbelastbare Verbindungsstücke, die in
									einer modernen Verarbeitungslinie eingesetzt
									werden,
								</li>
								<li>
									elektronische Schaltkreise, die in den
									Elementen der Palette angebracht sind,
								</li>
								<li>
									mobile Funk-Kommunikationssysteme
									(Smartphone, UHF-RFID-Lesegerät),
								</li>
								<li>stationäre Lesesysteme,</li>
								<li>
									<strong>
										offene Bedienungsanwendung samt
										öffentlichen Bibliotheken (API),
									</strong>
								</li>
								<li>spezielle Logistiksystemintegratoren.</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../../assets/img/pallet4-content-image-4.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<h4>Holzpalette:</h4>
							<p>
								Durch die Montagegenauigkeit sowie geeignete
								Materialien konnte Folgendes erreicht werden:
							</p>
							<ul>
								<li>
									<strong>hohe Beständigkeit</strong> (ca. 30
									% mehr als bei derzeit erhältlichen
									Paletten),
								</li>
								<li>
									<strong>längere Lebensdauer</strong> (dank
									einer besseren Spannungsneutralisierung),
								</li>
								<li>
									<strong>
										höhere Stabilität der Palette
									</strong>{" "}
									(dank der genauen Passung der
									Palettenkomponenten durch eine fachgerechte
									Montage),
								</li>
								<li>
									<strong>
										Gleichartigkeit der Palettenparameter
									</strong>{" "}
									(dank der Anwendung einer detaillierten
									technischen Kontrolle).
								</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../../assets/img/pallet4-content-image-5.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<h4>Die Elektronik besteht aus:</h4>
							<ul>
								<li>
									<strong>
										einer Mikroprozessor-Plattform
									</strong>{" "}
									(durch das Patentverfahren bestätigte
									einzigartige Eigenschaften, ein sehr hohes
									Maß an Flexibilität),
								</li>
								<li>
									<strong>einem Kommunikationssystem:</strong>
								</li>
								<ul>
									<li>
										Hardware-Ebene (BLE-Protokoll u. a.
										wegen seines geringen Stromverbrauchs),
									</li>
									<li>
										Software-Ebene (OPC UA-Standard, der die
										Möglichkeit der Kopplung mit ERP- und
										MES-Systemen bietet).{" "}
									</li>
								</ul>
							</ul>

							<br />
							<br />
							<p>
								Auffallend ist, dass derzeit marktseitig eine
								sehr hohe Ungenauigkeit bei der Montage der
								aktuell angebotenen Paletten festgestellt wird.
								<strong>
									Die Palette 4.0 ist für mindestens 4
									Ladezyklen einzusetzen
								</strong>
								, d. h. jeweils Auflegen von Ware auf die
								Palette, Beladung von LKW und Entladung.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-mobile">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<h2 className="color--white">Mobile Anwendung</h2>
						</div>
						<div className="col-lg-8">
							<div className="row align-items-end">
								<div className="col-md-9">
									<p>
										<strong>
											Macht direktes Ablesen von
											Palettendaten möglich, daher
											<span>
												KEINE BESCHAFFUNG SPEZIELLER
												GERÄTE ERFORDERLICH
											</span>
											.
										</strong>
									</p>
									<p>
										Die bereitgestellten
										Kommunikationsprotokolle ermöglichen
										eine eigenständige Produktentwicklung,
										die Anpassung des Datenformats an
										bestehende Systeme und eine beliebige
										Integration in Logistikplattformen.
									</p>
								</div>
								<div className="col-md-3">
									<img
										src={require("../../../assets/img/pallet4-content-image-mobile-app.png")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-contact">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<h2 className="color--main">Noch Fragen?</h2>
							<p>Haben Sie Interesse an unserem Produkt?</p>
						</div>
						<div className="col-lg-8 offset-lg-1">
							<img
								src={require("../../../assets/img/pallet4-content-image-contact.png")}
								alt=""
							/>
							<div className="pallet4-contact__row">
								<div>
									<Link
										to="/de/kontakt/"
										className="btn btn--white"
										data-hover="Kontaktieren Sie uns"
									>
										Kontaktieren Sie uns
										<span className="btn-arrows"></span>
									</Link>
								</div>
								<div>
									<a
										href={withPrefix(
											"/PalettenWerk_paleta_4-0_ulotka_a4_www_DE.pdf"
										)}
										target="_blank"
										rel="noreferrer"
										className="btn"
										data-hover="Flyer herunterladen"
									>
										Flyer herunterladen
										<span className="btn-arrows"></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};
export default SingleOffer;
